import React, { useContext, useEffect, useRef, useState } from 'react'
import OrdersTable from '../../../Components/Dashboard_Table/OrdersTable'
import { Box, Button, CircularProgress, FormControl, Grid, MenuItem, Pagination, Select, SelectChangeEvent, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import './Order.scss';
import MobileTable from '../../../Components/Mobile_Table/MobileTable';
import MobileMenuBar from '../../../Components/Mobile_Menubar/MobileMenubar';
import { useMutation, useQuery } from '@apollo/client';
import { OrdersPageQuery } from '../../../Operations/Queries';
import { ordersActionMutation } from '../../../Operations/Mutations';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { CountryContext } from '../../..';
import { useNavigate } from 'react-router-dom';
import * as mutations from "../../../Operations/Mutations";
import '../Product/Products.scss';

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}
function CustomTabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 1 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}


const Orders = () => {

   const [menuOpen, setMenuOpen] = useState(false);
   const [pendingOrders, setpendingOrders] = useState<any[]>([]);
   const [confirmedOrders, setConfirmedOrders] = useState<any[]>([]);
   const [cancelledOrders, setCancelledOrders] = useState<any[]>([]);
   const [awbOrders, setAwbOrders] = useState<any[]>([]);
   const [shippedOrders, setShippedOrders] = useState<any[]>([])
   const [deliveredOrders, setDeliveredOrders] = useState<any[]>([]);
   const [archivedOrders, setArchivedOrders] = useState<any[]>([]);
   const [selectedMenuItem, setSelectedMenuItem] = useState('Pending');
   const [filterOrder, setFilterOrder] = useState("Filter");
   const [column, setColumn] = useState("Columns");
   const [value, setValue] = useState(0);
   const [selectedPage, setSelectedPage] = useState<any>();
   const [currentPage, setCurrentPage] = useState({pending:1,confirmed:1,awb:1,shipped:1,delivered:1,cancelled:1,archived:1});
   const [ordersPendingPageInfo, setOrdersPendingPageInfo] = useState<any>();
   const [ordersConfirmedData, setOrdersConfirmedData] = useState<any>();
   const [ordersConfirmedPageInfo, setOrdersConfirmedPageInfo] = useState<any>();
   const [ordersDeliveredData, setOrdersDeliveredData] = useState<any>();
   const [ordersDeliveredPageInfo, setOrdersDeliveredPageInfo] = useState<any>();

   const [ordersAwbData, setOrdersAwbData] = useState<any>();
   const [ordersAwbPageInfo, setOrdersAwbPageInfo] = useState<any>(); 
   //const [searchTerm, setSearchTerm] = useState({pending:"",confirmed:"",awb:"",shipped:"",delivered:"",cancelled:"",archived:""});
   const [searchTerm, setSearchTerm] = useState<any[]>([]);
   //const [searchConfirmed, setSearchConfirmed] = useState('');
   const [search, setSearch] = useState({pending:"",confirmed:"",awb:"",shipped:"",delivered:"",cancelled:"",archived:""});
   const navigate = useNavigate();
   const buttonRef = useRef();

   const loggedInToken = localStorage.getItem("loggedInCust");
   console.log("token from localStorage ordersPage", loggedInToken)

   const {store} = useContext(CountryContext);

   const { data: OrdersListPendingData, loading: loadingPendingOrders, error: pendingOrdersError, refetch: refetchPendingOrders } = useQuery(OrdersPageQuery.ORDERS_PENDING, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
      variables: {
         order_number: search.pending,
         pageSize: 10,
         currentPage: currentPage.pending,
      }
   });
   const handleSearchTerms = ((e: any) => {
      const id = e.target.id;
      const val = e.target.value;
      setSearchTerm(prev => {
         return {
            ...prev,
            [id]: val
         }
      })
      console.log("SearchTerm: ",searchTerm);
   })
   const handleSearch = ((e: any) => {
      const searchTermNew = JSON.parse(JSON.stringify(searchTerm));
      const targetId = e.target.id;
      const objId = targetId.replace("_orders","")
      const id = `search_${targetId}`;
      const element = document.getElementById(id);
      console.log("SearchTerm: searchTermNew",searchTermNew);
      console.log("SearchTerm: ",id, element?.getAttribute('value'),"objId: ",objId)

      //const id = e.target.id;
      //const val = e.target.value;
      const val = searchTermNew[id];//(element?.getAttribute('value')=="") ? "" :  searchTermNew[id];
      setSearch(prev => {
         return {
            ...prev,
            [objId]: val
         }
      })
      //const element = document.getElementById(id);
      //document.getElementById(id)[0].value = searchTermNew[id];
      console.log("SearchTerm order number: ",search )
      element?.setAttribute('value',searchTermNew[id] );
      //setSearch(e.target.value);
      //refetchConfirmedOrders();
   })
   //console.log("OrdersListPendingData",OrdersListPendingData);
   //const ordersPendingData = OrdersListPendingData?.sellerOrders[0]?.items
   //const ordersPendingPageInfo = OrdersListPendingData?.sellerOrders[0]?.pageInfo
   //console.log("ordersPendingData:", ordersPendingData)

   const [revokeCustomertoken] = useMutation(mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
      {
         context: {
            headers: {
               Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
               Connection: "keep-alive",
               store: store,
            },
         },
      });
  const handleLogOut = async () => {
      console.log("logout clicked")
      try {
          const response = await revokeCustomertoken();
          console.log("response: ", response.data.revokeCustomerToken.result);
          localStorage.removeItem('selectedMenuItem');
          navigate("/")
      } catch (error) {
          console.error('Error revoking token:', error);
          localStorage.removeItem('selectedMenuItem');
          navigate("/")
      }
  }

   const errorPendingOrders = pendingOrdersError?.message;
   console.log("error:", errorPendingOrders)
   if(errorPendingOrders=="Seller is not loggedin") {
      handleLogOut();
   }

   const { data: OrdersListConfirmedData, refetch: refetchConfirmedOrders } = useQuery(OrdersPageQuery.ORDERS_CONFIRMED, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
      variables: {
         orderNumber: search.confirmed,
         pageSize: 10,
         currentPage: currentPage.confirmed,
      }
   });
   const handleSearchConfirmed = (e: any) => {
      console.log("order number: ",e.target.value)
      //setSearchConfirmed(e.target.value);
      //refetchConfirmedOrders();
   }
   
   const { data: OrdersListAwbData, refetch: refetchAwbOrders } = useQuery(OrdersPageQuery.ORDERS_AWB, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
      variables: {
         orderNumber: search.awb,
         pageSize: 10,
         currentPage: currentPage.awb,
      }
   });
   
   const { data: OrdersListShippedData, refetch: refetchShippedOrders } = useQuery(OrdersPageQuery.ORDERS_SHIPPED, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
      variables: {
         orderNumber: search.shipped,
         pageSize: 100,
         currentPage: currentPage.shipped,
      }
   });
   const ordersShippedData = OrdersListShippedData?.sellerOrdersList[0]?.items
   const ordersShippedPageInfo = OrdersListShippedData?.sellerOrdersList[0]?.pageInfo
   console.log("ordersShippedData: ", ordersShippedData)

   const { data: OrdersListDeliveredData, refetch: refetchDeliveredOrders } = useQuery(OrdersPageQuery.ORDERS_DELIVERED, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
      variables: {
         orderNumber: search.delivered,
         pageSize: 100,
         currentPage: currentPage.delivered,
      }
   });
   
   const { data: OrdersListCancelledData, refetch: refetchCancelledOrders } = useQuery(OrdersPageQuery.ORDERS_CANCELLED, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
      variables: {
         orderNumber: search.cancelled,
         pageSize: 100,
         currentPage: currentPage.cancelled,
      }
   });
   const ordersCancelledData = OrdersListCancelledData?.sellerOrdersList[0]?.items
   const ordersCancelledPageInfo = OrdersListCancelledData?.sellerOrdersList[0]?.pageInfo
   console.log("ordersCancelledData: ", ordersCancelledData)

   const { data: OrdersListArchivedData } = useQuery(OrdersPageQuery.ORDERS_ARCHIVED, {
      context: {
         headers: {
            Authorization: loggedInToken
               ? `Bearer ${JSON.parse(loggedInToken)}`
               : "",
            Connection: "keep-alive",
            store: store,
         },
      },
      variables: {
         orderNumber: search.archived,
         pageSize: 100,
         currentPage: currentPage.archived,
      }
   });
   
   const ordersArchivedData = OrdersListArchivedData?.sellerOrdersList[0]?.items
   const ordersArchivedPageInfo = OrdersListArchivedData?.sellerOrdersList[0]?.pageInfo
   console.log("ordersArchivedData: ", ordersArchivedData)

   const [confirmOrders, { error: confirmActionError }] = useMutation(
      ordersActionMutation.ORDER_CONFIRM,
      {
         context: {
            headers: {
               Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
               Connection: "keep-alive",
               store: store,
            },
         },
      }
   );

   const [cancelOrders] = useMutation(
      ordersActionMutation.ORDER_CANCEL,
      {
         context: {
            headers: {
               Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
               Connection: "keep-alive",
               store: store,
            },
         },
      }
   );

   const [generateAwb] = useMutation(
      ordersActionMutation.ORDER_GENERATE_AWB,
      {
         context: {
            headers: {
               Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
               Connection: "keep-alive",
               store: store,
            },
         },
      }
   );

   const [pickupRequest] = useMutation(
      ordersActionMutation.ORDER_PICKUP_REQUEST,
      {
         context: {
            headers: {
               Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
               Connection: "keep-alive",
               store: store,
            },
         },
      }
   );

   // Define the headCells for the table
   const headCellsForPending = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
   ];
   const headCellsForConfirmed = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
   ];
   const headCellsForAwb = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'waybillNumber', numeric: false, disablePadding: false, label: 'AWB Number' },
      { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
      { id: 'shipmentStatus', numeric: false, disablePadding: false, label: 'Shipment Status' },
   ];
   const headCellsForShipped = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'waybillNumber', numeric: false, disablePadding: false, label: 'AWB Number' },
      { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
   ];
   const headCellsForDelivered = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'waybillNumber', numeric: false, disablePadding: false, label: 'AWB Number' },
      { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
      { id: 'deliveryDate', numeric: false, disablePadding: false, label: 'Delivery Date' },
   ];
   const headCellsForCancelled = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'waybillNumber', numeric: false, disablePadding: false, label: 'AWB Number' },
      { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
   ];
   const headCellsForArchived = [
      { id: 'order', numeric: false, disablePadding: false, label: 'Order' },
      { id: 'waybillNumber', numeric: false, disablePadding: false, label: 'AWB Number' },
      { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
      { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
      { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
      { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
      { id: 'itemTotal', numeric: true, disablePadding: false, label: 'Item Total (AED)' },
   ];

   // Define the rows data for the table
   const rowsForPending = [
      { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
      { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
      { id: 3, order: 'ORD-SA-00005 239', date: 'Nov 20, 2022 9:47:28 AM', itemName: 'Segafredo - Capsule MyEspresso', itemPrice: 'AED 1053.00', quantity: 1, itemTotal: 1 },
      { id: 4, order: 'ORD-SA-00005 240', date: 'Nov 20, 2022 12:47:28 PM', itemName: 'Segafredo - Macinato Mocha', itemPrice: 'AED 950.00', quantity: 1, itemTotal: 1 },
   ];
   const rowsForConfirmed = [
      { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
      { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
   ];
   const rowsForAwb = [
      { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, shipmentStatus: 'Picked Up' },
      { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, shipmentStatus: 'Picked Up' },
   ];
   const rowsForShipped = [
      { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
      { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
   ];
   const rowsForDelivered = [
      { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, deliveryDate: 'Nov 20, 2022 9:47:28 AM' },
      { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, deliveryDate: 'Nov 23, 2022 9:47:28 AM' },
   ];
   const rowsForCancelled = [
      { id: 1, order: 'ORD-SA-00005 237', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
      { id: 2, order: 'ORD-SA-00005 238', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1 },
   ];
   const rowsForArchived = [
      { id: 1, order: 'ORD-SA-00005 300', date: 'Nov 16, 2022 9:47:28 AM', itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 1000.00', quantity: 1, itemTotal: 1 },
      { id: 2, order: 'ORD-SA-00005 300', date: 'Nov 19, 2022 6:30:28 PM', itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 1000.00', quantity: 1, itemTotal: 1 },
   ];
  
   const additionalActionsForPending = [
      {
         label: 'Confirm',
         buttonLabel: 'Confirm',
         onClick: async (row: any, cont: any) => {
            console.log('Confirm clicked for row:',cont, row);
            toast.info('Please wait while processing your request!', {
               position: toast.POSITION.TOP_RIGHT
            });
            const rowId = row.id;
            const itemId = row.id;
            const orderNumber = row.order;
            const finalPayload = {
               action: "orderAction",
               status: "accepted",
               order_number: orderNumber,
               item_id: itemId
            };
            try {
               const response = await confirmOrders({
                  variables: finalPayload
               });
               toast.success('Order confirmed', {
                  position: toast.POSITION.TOP_RIGHT
               });
               console.log("response from APi: ", response)
               // alert(`Order Confirmed for ${orderNumber}`)
               refetchPendingOrders();
               refetchConfirmedOrders();
            } catch (error) {
               toast.error('Error while confirming order', {
                  position: toast.POSITION.TOP_RIGHT
              });
               // console.log("error:", error)
               // alert("An error occurred while confirming the order.")
            }
         }
      },
      {
         label: 'Cancel',
         buttonLabel: 'Cancel',
         onClick: async (row: any) => {
            toast.info('Please wait while processing your request!', {
               position: toast.POSITION.TOP_RIGHT
            });
            console.log('Confirm clicked for row:', row);
            const rowId = row.id;
            const itemId = row.id;
            const orderNumber = row.order;
            const finalPayload = {
               action: "orderAction",
               status: "canceled",
               order_number: orderNumber,
               item_id: itemId
            };
            try {
               const response = await cancelOrders({
                  variables: finalPayload
               });
               toast.success('Order cancelled', {
                  position: toast.POSITION.TOP_RIGHT
              });
               console.log("response from APi: ", response)
               // alert(`Order Cancelled for ${orderNumber}`)
               refetchPendingOrders();
               refetchCancelledOrders();
            } catch (error) {
               toast.error('Error while cancelling Order', {
                  position: toast.POSITION.TOP_RIGHT
              });
               // console.log("error:", error)
               // alert("An error occurred while confirming the order.")
            }
         }
      }
   ];
   const additionalActionsForConfirmed = [
      {
         label: 'Generate AWB',
         buttonLabel: 'Generate AWB',
         onClick: async (row: any) => {
            toast.info('Please wait while processing your request!', {
               position: toast.POSITION.TOP_RIGHT
            });
            console.log('Generate AWB clicked for row:', row);
            const rowId = row.id;
            const itemId = row.id;
            const orderNumber = row.order;
            const finalPayload = {
               action: "orderAction",
               status: "generate_awb",
               order_number: orderNumber,
               item_id: itemId
            };
            try {
               console.log('Generate AWB clicked for row: 111111');
               const response = await generateAwb({
                  variables: finalPayload
               });
               console.log("response from APi: ", response)
               if(response?.data?.sellerOrderAction?.order_item_status=="awb_generated") {
                  window.open(response?.data?.sellerOrderAction?.file)
               }
               console.log("sellerOrderAction: ",response)
               if(response?.data?.sellerOrderAction==null) {
                  toast.warning('An AWB will be generated shortly by the Jebelz team, contact customer@jebelz.com', {
                     position: toast.POSITION.TOP_RIGHT
                  });
               }else {
                  toast.success('Successfully generated AWB', {
                     position: toast.POSITION.TOP_RIGHT
                  });
               }
               
               
               // alert(`Order Confirmed for ${orderNumber}`)
               refetchConfirmedOrders();
               refetchAwbOrders();
               
            } catch (error) {
               console.log("error",error)
               toast.error('Error generating AWB', {
                  position: toast.POSITION.TOP_RIGHT
              });
               // console.log("error:", error)
            }
         }
      },
   ];
   const additionalActionsForAwb = [
      {
         label: 'Shipment Status',
         buttonLabel: 'Picked Up',
         onClick: async (row: any) => {
            //console.log('Please wait while processing your request!');
            toast.info('Please wait while processing your request!', {
               position: toast.POSITION.TOP_RIGHT
            });
            console.log('PickUp clicked for row:', row);
            const rowId = row.id;
            const itemId = row.id;
            const orderNumber = row.order;
            const finalPayload = {
               action: "orderAction",
               status: "ship_item",
               order_number: orderNumber,
               item_id: itemId
            };
            try {
               const response = await pickupRequest({
                  variables: finalPayload
               });
               console.log("response from APi pickp requrest: ", response)
               
               toast.success('Successfully pickup requested', {
                  position: toast.POSITION.TOP_RIGHT
               });
               
               refetchAwbOrders();
               refetchShippedOrders();
            } catch (error) {
               console.log("error",error)
               toast.error('Error generating AWB', {
                  position: toast.POSITION.TOP_RIGHT
              });
               // console.log("error:", error)
            }
         }
      },
   ];
   const additionalActionsForDelivered = [
      {
         label: 'Delivery Date',
         buttonLabel: 'Delivery Date',
         onClick: (row: any) => {
            toast.info('Please wait while processing your request!', {
               position: toast.POSITION.TOP_RIGHT
            });
            console.log('PickUp clicked for row:', row);
         }
      },
   ];

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      //console.log("event target:",event.currentTarget.innerHTML.split('<span')[0].toLowerCase())
      setSelectedMenuItem(event.currentTarget.innerHTML.split('<span')[0]);
      setValue(newValue);
   };

   const handlePageChange = (event: any, value: any) => {
      setCurrentPage(value);
      setSelectedPage(value);
    };
   function handlePaginationChange(page: any, pageNumber: any) {
      //currentPage[value];
      console.log("page number: ",value, pageNumber, selectedMenuItem);
      if(value==0) {
         setCurrentPage(prev => {
            return {
               ...prev, pending: pageNumber
            }
         })
      }
      else if(value==1) {
         setCurrentPage(prev => {
            return {
               ...prev, confirmed: pageNumber
            }
         })
      }
      else if(value==2) {
         setCurrentPage(prev => {
            return {
               ...prev, awb: pageNumber
            }
         })
      }
      else if(value==3) {
         setCurrentPage(prev => {
            return {
               ...prev, shipped: pageNumber
            }
         })
      }
      else if(value==4) {
         setCurrentPage(prev => {
            return {
               ...prev, delivered: pageNumber
            }
         })
      }
      else if(value==5) {
         setCurrentPage(prev => {
            return {
               ...prev, cancelled: pageNumber
            }
         })
      }
      else if(value==5) {
         setCurrentPage(prev => {
            return {
               ...prev, archived: pageNumber
            }
         })
      }
      //const newObj = {...currentPage};
      //newObj[selectedMenuItem] = "";
      /*const newObj = {pending:1,confirmed:1,awb:1,shipped:1,delivered:1,cancelled:1,archived:1};
      */
      
   }

   const filter = ["Filter", "quarterly", "monthly", "weekly", "daily"];
   const handlefilterChange = (event: SelectChangeEvent) => {
      setFilterOrder(event.target.value as string);
   };

   useEffect(() => {
      console.log("selected", filterOrder);
   }, [filterOrder])

   const columnPeriod = ["Columns", "quarterly", "monthly", "weekly", "daily"];
   const handlecolumnChange = (event: SelectChangeEvent) => {
      setColumn(event.target.value as string);
   };

   useEffect(() => {
      console.log("selected", column);
   }, [column])


   const menuItems = [
      'Pending',
      'Confirmed',
      'AWB',
      'Shipped',
      'Delivered',
      'Cancelled',
      'Archived',
   ];

   const handleMenuItem = (itemName: string) => {
      setSelectedMenuItem(itemName);
      setMenuOpen(false);
   }

   const getTableHeadCells = (menuItem: string) => {
      switch (menuItem) {
         case 'Pending':
            return headCellsForPending;
         case 'Confirmed':
            return headCellsForConfirmed;
         case 'AWB':
            return headCellsForAwb;
         case 'Shipped':
            return headCellsForShipped;
         case 'Delivered':
            return headCellsForDelivered;
         case 'Cancelled':
            return headCellsForCancelled;
         case 'Archived':
            return headCellsForArchived;
      }
   };

   const getTableRows = (menuItem: string) => {
      switch (menuItem) {
         case 'Pending':
            return pendingOrders;
         case 'Confirmed':
            return confirmedOrders;
         case 'AWB':
            return awbOrders;
         case 'Shipped':
            return shippedOrders;
         case 'Delivered':
            return deliveredOrders;
         case 'Cancelled':
            return cancelledOrders;
         case 'Archived':
            return archivedOrders;
      }
   };

   const getAdditionalActions = (menuItem: string) => {
      switch (menuItem) {
         case 'Pending':
            return additionalActionsForPending;
         case 'Confirmed':
            return additionalActionsForConfirmed;
         case 'AWB':
            return additionalActionsForAwb;
         case 'Delivered':
            return additionalActionsForDelivered;
      }
   };

   useEffect(() => {
      //console.log("OrdersListPendingData",OrdersListPendingData);
      const ordersPendingData = OrdersListPendingData?.sellerOrdersList[0]?.items
      setOrdersPendingPageInfo(OrdersListPendingData?.sellerOrdersList[0]?.pageInfo)
      //console.log("ordersPendingData:", ordersPendingData)
      const transformed = ordersPendingData?.map((order: any, index: any) => ({
         id: order.item_id,
         order: order.order_number,
         date: order.date,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
      }));
      setpendingOrders(transformed)
   }, [OrdersListPendingData]);
   //console.log("pendingOrders List", pendingOrders)

   useEffect(() => {
      setOrdersConfirmedData(OrdersListConfirmedData?.sellerOrdersList[0]?.items);
      setOrdersConfirmedPageInfo(OrdersListConfirmedData?.sellerOrdersList[0]?.pageInfo);
      const transformed = OrdersListConfirmedData?.sellerOrdersList[0]?.items?.map((order: any, index: any) => ({
         id: order.item_id,
         order: order.order_number,
         date: order.date,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
         waybillNumber: order.waybill_no,
         waybillFile: order.waybill_file,
      }));
   //console.log("ordersConfirmedData:", ordersConfirmedData)
      setConfirmedOrders(transformed)
   }, [OrdersListConfirmedData]);
   console.log("confirmedOrders List", confirmedOrders)

   useEffect(() => {
      const transformed = ordersCancelledData?.map((order: any, index: any) => ({
         id: order.item_id,
         order: order.order_number,
         waybillNumber: order.waybill_no,
         waybillFile: order.waybill_file,
         date: order.date,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
      }));
      setCancelledOrders(transformed)
   }, [ordersCancelledData]);
   console.log("cancelledOrders List", cancelledOrders)

   useEffect(() => {
      setOrdersAwbData(OrdersListAwbData?.sellerOrdersList[0]?.items)
      setOrdersAwbPageInfo(OrdersListAwbData?.sellerOrdersList[0]?.pageInfo)
      console.log("ordersAwbData: ", ordersAwbData)
      const transformed = OrdersListAwbData?.sellerOrdersList[0]?.items?.map((order: any, index: any) => ({
         id: order.item_id,
         order: order.order_number,
         waybillNumber: order.waybill_no,
         waybillFile: order.waybill_file,
         date: order.date,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
         status: order.status
      }));
      setAwbOrders(transformed)
   }, [OrdersListAwbData]);
   console.log("AWBOrders List", awbOrders)

   useEffect(() => {
      const transformed = ordersShippedData?.map((order: any, index: any) => ({
         id: order.item_id,
         order: order.order_number,
         waybillNumber: order.waybill_no,
         waybillFile: order.waybill_file,
         date: order.date,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
      }));
      setShippedOrders(transformed)
   }, [ordersShippedData]);
   console.log("ShippedOrders List", shippedOrders)

   useEffect(() => {
      setOrdersDeliveredData(OrdersListDeliveredData?.sellerOrdersList[0]?.items)
      setOrdersDeliveredPageInfo(OrdersListDeliveredData?.sellerOrdersList[0]?.pageInfo)
      console.log("ordersDeliveredData: ", ordersDeliveredData)
      const transformed = OrdersListDeliveredData?.sellerOrdersList[0]?.items?.map((order: any, index: any) => ({
         id: order.item_id,
         order: order.order_number,
         waybillNumber: order.waybill_no,
         waybillFile: order.waybill_file,
         date: order.date,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
      }));
      setDeliveredOrders(transformed)
   }, [ordersDeliveredData]);
   console.log("DeliveredOrders List", deliveredOrders)

   useEffect(() => {
      const transformed = ordersArchivedData?.map((order: any, index: any) => ({
         id: order.item_id,
         order: order.order_number,
         waybillNumber: order.waybill_no,
         waybillFile: order.waybill_file,
         date: order.date,
         itemName: order.product_name,
         itemPrice: order.price,
         quantity: order.qty,
         itemTotal: order.sub_total,
      }));
      setArchivedOrders(transformed)
   }, [ordersArchivedData]);
   console.log("ArchivedOrders List", archivedOrders)


   return (
      <div>
         {/* mobile tabs menu  */}
         <MobileMenuBar menuItems={menuItems} onItemClick={handleMenuItem} selectedMenuItem={selectedMenuItem} />
         <Box sx={{ width: '100%' }} className="orders-tabs ">
            <Grid className='filter-tabs-block' container spacing={3}>
               <Grid item xs={12} sm={12} md={7}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='order-tabs-head'>
                        <Tab label="Pending" {...a11yProps(0)} className='tabs-title' />
                        <Tab label="Confirmed" {...a11yProps(1)} className='tabs-title' />
                        <Tab label="AWB" {...a11yProps(2)} className='tabs-title' />
                        <Tab label="Shipped" {...a11yProps(3)} className='tabs-title' />
                        <Tab label="Delivered" {...a11yProps(4)} className='tabs-title' />
                        <Tab label="Cancelled" {...a11yProps(5)} className='tabs-title' />
                        <Tab label="Archived" {...a11yProps(6)} className='tabs-title' />
                     </Tabs>
                  </Box>
               </Grid>
               <Grid item xs={12} sm={12} md={5}>
                  <div className='order-filter-section'>
                     <FormControl className='select-form'>
                        <Select
                           labelId="demo-simple-select-label" 
                           id="demo-simple-select"
                           value={filterOrder}
                           label="Yearly"
                           onChange={handlefilterChange}
                        >
                           {filter.map((item, index) => (
                              <MenuItem key={index} value={item} className='menuitem-select'>
                                 {item}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <FormControl className='select-form'>
                        <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={column}
                           label="Yearly"
                           onChange={handlecolumnChange}
                        >
                           {columnPeriod.map((item: any, index: any) => (
                              <MenuItem key={index} value={item} className='menuitem-select'>
                                 {item}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </div>
               </Grid>
            </Grid>
            {
               pendingOrdersError ? (
                  <p className='error-text'>{errorPendingOrders}</p>
               ) : (
                  loadingPendingOrders ? (
                     <CircularProgress className='loader' />
                  ) : (
                     <>
                        <CustomTabPanel value={value} index={0}>
                           <Toaster/>
                           <div className='main'>
                              <div className='btn-group'>
                                 <TextField placeholder='Search' name="search_pending_orders" id="search_pending_orders" onChange={handleSearchTerms}/>
                                 <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearch}>
                                    <Typography className='btn-text' id="pending_orders">Search</Typography>
                                 </Button>
                              </div>
                              <OrdersTable headCells={headCellsForPending} rows={pendingOrders} ButtonActions={additionalActionsForPending} pageChange={handlePageChange} pageType="pending" pageNumber={ordersPendingPageInfo?.current_page} />
                              <Box sx={{ pt: 5 }}>
                                 <Stack spacing={2}>
                                    <Pagination
                                    count={(ordersPendingPageInfo?.total_pages > 1) ? Math.round(ordersPendingPageInfo?.total_pages/ordersPendingPageInfo?.page_size) : 0}
                                    shape="rounded"
                                    onChange={handlePaginationChange}
                                    defaultPage={ordersPendingPageInfo?.current_page}
                                    />
                                 </Stack>
                              </Box>
                           </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                           <Toaster/>
                           <div className='main'>
                              <div className='btn-group'>
                                 <TextField placeholder='Search' name="search_confirmed_orders" id="search_confirmed_orders" onChange={handleSearchTerms}/>
                                 <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearch}>
                                    <Typography className='btn-text' id="confirmed_orders">Search</Typography>
                                 </Button>
                              </div>
                              <OrdersTable headCells={headCellsForConfirmed} rows={confirmedOrders} ButtonActions={additionalActionsForConfirmed} pageChange={handlePageChange} pageType="confirmed" pageNumber={ordersConfirmedPageInfo?.current_page}/>
                              <Box sx={{ pt: 5 }}>
                                 <Stack spacing={2}>
                                    <Pagination
                                    count={(ordersConfirmedPageInfo?.total_pages > 1) ? Math.round(ordersConfirmedPageInfo?.total_pages/ordersConfirmedPageInfo?.page_size) : 0}
                                    shape="rounded"
                                    onChange={handlePaginationChange}
                                    defaultPage={ordersConfirmedPageInfo?.current_page}
                                    />
                                 </Stack>
                              </Box>
                           </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                           <Toaster/>
                           <div className='main'>
                              <div className='btn-group'>
                                 <TextField placeholder='Search' name="search_awb_orders" id="search_awb_orders" onChange={handleSearchTerms}/>
                                 <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearch}>
                                    <Typography className='btn-text' id="awb_orders">Search</Typography>
                                 </Button>
                              </div>
                              <OrdersTable headCells={headCellsForAwb} rows={awbOrders} ButtonActions={additionalActionsForAwb} pageChange={handlePageChange} pageType="awb" pageNumber={ordersAwbPageInfo?.current_page}/>
                              <Box sx={{ pt: 5 }}>
                                 <Stack spacing={2}>
                                    <Pagination
                                    count={(ordersAwbPageInfo?.total_pages > 1) ? Math.round(ordersAwbPageInfo?.total_pages/ordersAwbPageInfo?.page_size) : 0}
                                    shape="rounded"
                                    onChange={handlePaginationChange}
                                    defaultPage={ordersAwbPageInfo?.current_page}
                                    />
                                 </Stack>
                              </Box>
                           </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                           <Toaster/>
                           <div className='main'>
                              <div className='btn-group'>
                                 <TextField placeholder='Search' name="search_shipped_orders" id="search_shipped_orders" onChange={handleSearchTerms}/>
                                 <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearch}>
                                    <Typography className='btn-text' id="shipped_orders">Search</Typography>
                                 </Button>
                              </div>
                              <OrdersTable headCells={headCellsForShipped} rows={shippedOrders} pageChange={handlePageChange} pageType="shipped" pageNumber={ordersShippedPageInfo?.current_page}/>
                              <Box sx={{ pt: 5 }}>
                                 <Stack spacing={2}>
                                    <Pagination
                                    count={(ordersShippedPageInfo?.total_pages > 1) ? Math.round(ordersShippedPageInfo?.total_pages/ordersShippedPageInfo?.page_size) : 0}
                                    shape="rounded"
                                    onChange={handlePaginationChange}
                                    defaultPage={ordersShippedPageInfo?.current_page}
                                    />
                                 </Stack>
                              </Box>
                           </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                           <div className='main'>
                              <div className='btn-group'>
                                 <TextField placeholder='Search' name="search_delivered_orders" id="search_delivered_orders" onChange={handleSearchTerms}/>
                                 <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearch}>
                                    <Typography className='btn-text' id="delivered_orders">Search</Typography>
                                 </Button>
                              </div>
                              <OrdersTable headCells={headCellsForDelivered} rows={deliveredOrders} ButtonActions={additionalActionsForDelivered} pageChange={handlePageChange} pageType="delivered" pageNumber={ordersDeliveredPageInfo?.current_page}/>
                              <Box sx={{ pt: 5 }}>
                                 <Stack spacing={2}>
                                    <Pagination
                                    count={(ordersDeliveredPageInfo?.total_pages > 1) ? Math.round(ordersDeliveredPageInfo?.total_pages/ordersDeliveredPageInfo?.page_size) : 0}
                                    shape="rounded"
                                    onChange={handlePaginationChange}
                                    defaultPage={ordersDeliveredPageInfo?.current_page}
                                    />
                                 </Stack>
                              </Box>
                           </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}>
                           <div className='main'>
                              <div className='btn-group'>
                                 <TextField placeholder='Search' name="search_cancelled_orders" id="search_cancelled_orders" onChange={handleSearchTerms}/>
                                 <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearch}>
                                    <Typography className='btn-text' id="cancelled_orders">Search</Typography>
                                 </Button>
                              </div>
                              <OrdersTable headCells={headCellsForCancelled} rows={cancelledOrders} pageChange={handlePageChange} pageType="cancelled" pageNumber={ordersCancelledPageInfo?.current_page}/>
                              <Box sx={{ pt: 5 }}>
                                 <Stack spacing={2}>
                                    <Pagination
                                    count={(ordersCancelledPageInfo?.total_pages > 1) ? Math.round(ordersCancelledPageInfo?.total_pages/ordersCancelledPageInfo?.page_size) : 0}
                                    shape="rounded"
                                    onChange={handlePaginationChange}
                                    defaultPage={ordersCancelledPageInfo?.current_page}
                                    />
                                 </Stack>
                              </Box>
                           </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={6}>
                           <div className='main'>
                              <div className='btn-group'>
                                 <TextField placeholder='Search' name="search_archived_orders" id="search_archived_orders" onChange={handleSearchTerms}/>
                                 <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearch}>
                                    <Typography className='btn-text' id="archived_orders">Search</Typography>
                                 </Button>
                              </div>
                              <OrdersTable headCells={headCellsForArchived} rows={archivedOrders} pageChange={handlePageChange} pageType="archived" pageNumber={ordersArchivedPageInfo?.current_page}/>
                              <Box sx={{ pt: 5 }}>
                                 <Stack spacing={2}>
                                    <Pagination
                                    count={(ordersArchivedPageInfo?.total_pages > 1) ? Math.round(ordersArchivedPageInfo?.total_pages/ordersArchivedPageInfo?.page_size) : 0}
                                    shape="rounded"
                                    onChange={handlePaginationChange}
                                    defaultPage={ordersArchivedPageInfo?.current_page}
                                    />
                                 </Stack>
                              </Box>
                           </div>
                        </CustomTabPanel>
                     </>
                  )
               )
            }
            {menuItems.map((menuItem, index) => (
               selectedMenuItem === menuItem && (
                  <>
                  <div className='mobile-wrapper'>
                     <Grid item xs={12} sm={12} md={5}>
                        <div className='order-filter-section'>
                           <FormControl className='select-form'>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 value={filterOrder}
                                 label="Yearly"
                                 onChange={handlefilterChange}
                              >
                                 {filter.map((item, index) => (
                                    <MenuItem key={index} value={item} className='menuitem-select'>
                                       {item}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                           <FormControl className='select-form'>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 value={column}
                                 label="Yearly"
                                 onChange={handlecolumnChange}
                              >
                                 {columnPeriod.map((item: any, index: any) => (
                                    <MenuItem key={index} value={item} className='menuitem-select'>
                                       {item}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                        </div>
                     </Grid>
                     <MobileTable key={index} headCells={getTableHeadCells(menuItem)} rows={getTableRows(menuItem)} ButtonActions={getAdditionalActions(menuItem)} />
                     </div>
                  </>
               )
            ))}
         </Box>
      </div >
   )
}

export default Orders